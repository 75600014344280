<template>
  <div class="signBox">
    <img class="loginImg" src="../assets/img/Frame4388.png" alt="" />
    <div class="FromBox">
      <div class="signMain">
        <h2>加入我们</h2>
        <div class="moldBox">
          <!-- 选择身份 -->
          <div class="moldNav">
            <label class="elect" for="">请选择身份:</label>
            <div
              class="person"
              @click="headTab(1)"
              :class="{ active: add === 1 }"
            >
              <img class="personImg" :src="img" alt="" />
              <span class="personTitle">个体户</span>
            </div>
            <div
              class="firm"
              @click="headTab(2)"
              :class="{ active: add === 2 }"
            >
              <img class="firmImg" :src="img1" alt="" />
              <span class="firmTitle">企业</span>
            </div>
          </div>

          <!-- 个人内容 -->
          <div class="perBox" v-show="add == 1">
            <!-- 名称 -->
            <div class="company">
              <label class="companyTitle1" for="">姓名:</label>
              <input
                class="designation"
                type="text"
                placeholder="请输入姓名"
                v-model="name"
              />
            </div>
            <!-- 地址 -->
            <div class="site">
              <label class="siteTitle1" for="">长驻地址:</label>
              <select
                class="per"
                name="provinceId"
                id="provinceId"
                v-model="provinceId"
              >
                <option
                  :value="item.province_id"
                  v-for="(item, index) in province"
                  :key="index"
                >
                  {{ item.province_name }}
                </option>
              </select>
              <select class="per" name="cityid" id="cityid" v-model="cityid">
                <option
                  :value="item.city_id"
                  v-for="(item, index) in city"
                  :key="index"
                >
                  {{ item.city_name }}
                </option>
              </select>
              <select
                class="per"
                name="countyid"
                id="countyid"
                v-model="countyid"
              >
                <option
                  :value="item.county_id"
                  v-for="(item, index) in county"
                  :key="index"
                >
                  {{ item.county_name }}
                </option>
              </select>
              <input
                class="detail"
                type="text"
                placeholder="详细地址"
                v-model="address"
              />
            </div>
            <!-- 手机号 -->
            <div class="phone">
              <label class="ohoneTitle" for="">手机号:</label>
              <input
                class="auth"
                type="text"
                placeholder="请输入手机号"
                v-model="mobile"
              />
              <!-- <span class="gain">获取验证码</span> -->
              <input
                class="note"
                type="text"
                placeholder="请输入短信验证码"
                v-model="code"
              />
              <span class="gain" v-if="sendcode == 0">获取验证码</span>
              <span class="gain-g" v-if="sendcode == 1" @click="sendsms"
                >获取验证码</span
              >
              <span class="gain-x" v-if="sendcode == 2">{{ time }}秒</span>
            </div>
            <!-- 身份证照 -->
            <div class="rank">
              <label class="rankTitle" for="">身份证照:</label>
              <el-upload
                ref="fileUpload"
                :multiple="true"
                list-type="picture-card"
                :file-list="fileList1"
                :on-change="handleChange1"
                :auto-upload="false"
                :on-error="handleError1"
                :http-request="uploadFiles1"
                :on-exceed="handleExceed1"
                :limit="1"
                action=""
              >
                <el-icon><Plus /></el-icon>
              </el-upload>

              <el-dialog v-model="dialogVisible1">
                <img width="100%" :src="dialogImageUrl1" alt="" />
              </el-dialog>
              <div class="fenTitle">请上传带有头像的一面</div>
            </div>
          </div>
          <!-- 企业内容 -->
          <div class="firmBox" v-show="add == 2">
            <!-- 公司名称 -->
            <div class="company">
              <label class="companyTitle" for="">公司名称:</label>
              <input
                class="designation1"
                type="text"
                placeholder="请输入公司全称"
                v-model="companyname"
              />
            </div>
            <!-- 地址 -->
            <div class="site">
              <label class="siteTitler" for="">地址:</label>
              <select
                class="qi"
                name="provinceId"
                id="provinceId"
                v-model="provinceId"
              >
                <option
                  :value="item.province_id"
                  v-for="(item, index) in province"
                  :key="index"
                >
                  {{ item.province_name }}
                </option>
              </select>
              <select class="qi" name="cityid" id="cityid" v-model="cityid">
                <option
                  :value="item.city_id"
                  v-for="(item, index) in city"
                  :key="index"
                >
                  {{ item.city_name }}
                </option>
              </select>
              <select
                class="qi"
                name="countyid"
                id="countyid"
                v-model="countyid"
              >
                <option
                  :value="item.county_id"
                  v-for="(item, index) in county"
                  :key="index"
                >
                  {{ item.county_name }}
                </option>
              </select>
              <input
                class="detail"
                type="text"
                placeholder="详细地址"
                v-model="address"
              />
            </div>
            <!-- 手机号 -->
            <div class="phone">
              <label class="ohoneTitle" for="">手机号:</label>
              <input
                class="auth"
                type="text"
                placeholder="请输入手机号"
                v-model="mobile"
              />
              <!-- <span class="gain">获取验证码</span> -->
              <input
                class="note"
                type="text"
                placeholder="请输入短信验证码"
                v-model="code"
              />
              <span class="gain" v-if="sendcode == 0">获取验证码</span>
              <span class="gain-g" v-if="sendcode == 1" @click="sendsms"
                >获取验证码</span
              >
              <span class="gain-x" v-if="sendcode == 2">{{ time }}秒</span>
            </div>
            <!-- 营业执照 -->
            <div class="business">
              <label class="businessTitle" for="">营业执照:</label>
              <el-upload
                ref="fileUpload"
                :multiple="true"
                list-type="picture-card"
                :file-list="fileList"
                :on-change="handleChange"
                :auto-upload="false"
                :on-error="handleError"
                :http-request="uploadFiles"
                :on-exceed="handleExceed"
                :limit="1"
                action=""
              >
                <el-icon><Plus /></el-icon>
              </el-upload>

              <el-dialog v-model="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="" />
              </el-dialog>
              <div class="fenTitle1">请上传营业执照</div>
            </div>
          </div>

          <!-- 勾选同意 -->
          <div class="perBotm">
            <div class="deal">
              <input type="checkbox" id="remember" v-model="remember" />
              <span class="dealTitle"
                >同意<i @click="agreement">《服务协议》</i>和<i @click="policy">《隐私政策》</i></span
              >
            </div>
            <!-- 提交 -->
            <button class="refer" @click="submit">提交</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as api from "../api/index";
import { compress } from "../api/pic";
import axios from "axios";
import { effect, ref } from "vue";
import { Delete, Download, Plus, ZoomIn } from "@element-plus/icons-vue";

const dialogImageUrl = ref("");
const dialogVisible = ref(false);
const dialogImageUrl1 = ref("");
const dialogVisible1 = ref(false);
const disabled = ref(false);
export default {
  data() {
    return {
      add: 1,
      dialogImageUrl,
      dialogVisible,
      dialogImageUrl1,
      dialogVisible1,
      disabled: false,
      mobile: "",
      code: "",
      time: 0,
      companyname: "",
      name: "",
      province: [],
      provinceId: 0,
      city: [{ city_id: 0, city_name: "市" }],
      cityid: 0,
      county: [{ county_id: 0, county_name: "区" }],
      countyid: 0,
      address: "",
      remember: false,
      sendcode: 0,
      timer: "",
      uploadpic1: "",
      uploadpic2: "",
      uploadUrl: "",
      uploadData: {},
      fileList: [],
      fileList1: [],
      filelist_temp: [],
      img: require("../assets/img/Frame766.png"),
      img1: require("../assets/img/Frame755.png"),
      imgs: require("../assets/img/Frame76.png"),
      imgs1: require("../assets/img/Frame75.png"),
    };
  },
  async created() {
    let res = await api.getprovincelist();
    this.province = res.data;
    console.log(this.province);
    this.img = require("../assets/img/Frame76.png");
  },
  methods: {
    headTab(e) {
      if (e == 1) {
        this.add = 1;
      }
      if (e == 2) {
        this.add = 2;
      }
      this.img = require("../assets/img/Frame766.png");
      this.img1 = require("../assets/img/Frame755.png");
      if (e == 1) {
        this.img = require("../assets/img/Frame76.png");
      }
      if (e == 2) {
        this.img1 = require("../assets/img/Frame75.png");
      }
    },

    agreement () {
      this.$router.push('/agreement')
    },
    policy() {
      this.$router.push('/policy')
    },

    handleChange(file, files) {},
    //文件超出个数限制时的钩子
    handleExceed1(files, fileList) {
      this.$notify.warning({
        title: "提示",
        message: "以达到个数上限,请移除后再添加！",
        duration: 2000,
      });
    },
    handleExceed(files, fileList) {
      this.$notify.warning({
        title: "提示",
        message: "以达到个数上限,请移除后再添加！",
        duration: 2000,
      });
    },
    submit() {
      if (this.add == 1) {
        if (this.name == "") {
          this.$notify.error({
            title: "错误",
            message: "请填写你的姓名",
            duration: 2000,
          });
          return;
        }
        if (this.mobile == "") {
          this.$notify.error({
            title: "错误",
            message: "请填写你的手机号码",
            duration: 2000,
          });
          return;
        }
        if (this.code == "") {
          this.$notify.error({
            title: "错误",
            message: "请输入验证码",
            duration: 2000,
          });
          return;
        }
        if (
          this.address == "" ||
          this.provinceId == "" ||
          this.cityid == "" ||
          this.countyid == ""
        ) {
          this.$notify.error({
            title: "错误",
            message: "请完善你的常驻地址",
            duration: 2000,
          });
          return;
        }

        if (!this.fileList1 || !this.fileList1.length) {
          this.$notify.error({
            title: "错误",
            message: "请选择上传的文件",
            duration: 2000,
          });
          return;
        }
        if (this.remember == false) {
          this.$notify.error({
            title: "错误",
            message: "请同意用户及隐私协议",
            duration: 2000,
          });
          return;
        }

        this.fileList1[0].status = "ready";
        let data = {
          type: this.add,
          name: this.name,
          mobile: this.mobile,
          code: this.code,
          province: this.provinceId,
          city: this.cityid,
          county: this.countyid,
          address: this.address,
        };
        this.uploadFiles({ file: this.fileList1[0], data: data });
      } else {
        if (this.companyname == "") {
          this.$notify.error({
            title: "错误",
            message: "请填写公司名称",
            duration: 2000,
          });
          return;
        }
        if (this.mobile == "") {
          this.$notify.error({
            title: "错误",
            message: "请填写你的手机号码",
            duration: 2000,
          });
          return;
        }
        if (this.code == "") {
          this.$notify.error({
            title: "错误",
            message: "请输入验证码",
            duration: 2000,
          });
          return;
        }
        if (
          this.address == "" ||
          this.provinceId == "" ||
          this.cityid == "" ||
          this.countyid == ""
        ) {
          this.$notify.error({
            title: "错误",
            message: "请完善你的地址",
            duration: 2000,
          });
          return;
        }
        if (!this.fileList || !this.fileList.length) {
          this.$notify.error({
            title: "错误",
            message: "请选择上传的文件",
            duration: 2000,
          });
          return;
        }
        if (this.remember == false) {
          this.$notify.error({
            title: "错误",
            message: "请同意用户及隐私协议",
            duration: 2000,
          });
          return;
        }
        let data = {
          type: this.add,
          name: this.companyname,
          mobile: this.mobile,
          code: this.code,
          province: this.provinceId,
          city: this.cityid,
          county: this.countyid,
          address: this.address,
        };
        this.uploadFiles({ file: this.fileList[0], data: data });
      }

    },
    uploadFiles(param) {
      let _this = this;
      let formData = new FormData();
      formData.append("data", JSON.stringify(param.data));
      // 压缩后上传
      this.compressAvatard(param.file, 1024 * 3, function (res) {
        formData.append("pic", res);
        axios
          .post("https://dispatch-test.nituowola.com/site/upload-pic", formData)
          .then((res) => {
            if (res.data.code == 1) {
              _this.$notify.success({
                title: "成功",
                message: "注册已成功，请等待你拖我拉审核",
                duration: 2000,
              });
              
              setTimeout(_this.$router.push('/login'),2000);
              //location.reload();
              return;
            } else {
              _this.$notify.error({
                title: "失败",
                message: res.data.message,
                duration: 2000,
              });
              return;
            }
          });
      });
    },
    handleError(err, file, fileList) {
      let errJson = JSON.stringify(err);
      if (errJson && errJson.length > 50) {
        errJson = "上传失败";
      }
      //上传失败处理
      this.$notify.error({
        title: "失败",
        message: errJson,
        duration: 3000,
      });
      console.error(errJson, err);
    },
    beforeClose() {},
    //把图片文件作为参数传递到方法中
    compressAvatard(file, kb, callback) {
      let _this = this;
      let raw = file.raw ? file.raw : file;
      compress(raw, function (val) {
        // val是Blob类型，转换为file类型
        let newfile = new window.File([val], file.name, { type: raw.type });
        newfile.uid = file.uid;
        // 压缩率过低则停止压缩, 防止死循环
        if (newfile.size / 1024 > kb && newfile.size / file.size < 0.9) {
          console.log("图片过大:" + newfile.size + ",已压缩");
          _this.compressAvatard(newfile, kb, callback);
        } else {
          callback(newfile);
          console.log("压缩后大小:" + newfile.size);
        }
      });
    },
    async sendsms() {
      let res = await api.regmobile(this.mobile, this.add, this.companyname);

      if (res.code == 1) {
        const TIME_COUNT = 60;
        if (this.time == 0) {
          this.time = TIME_COUNT;
          this.sendcode = 2;
          this.timer = setInterval(() => {
            if (this.time > 0 && this.time <= TIME_COUNT) {
              this.time--;
            } else {
              if (this.mobile.length == 11) {
                this.sendcode = 1;
              } else {
                this.sendcode = 0;
              }
              clearInterval(this.timer);
              this.timer = null;
            }
          }, 1000);
        }
      } else {
        this.$notify.error({
          title: "错误",
          message: res.message,
          duration: 2000,
        });
        return;
      }
    },
  },
  watch: {
    async provinceId(newval) {
      if (newval > 0) {
        let res = await api.getcitylist(newval);
        this.city = res.data;
        this.cityid = 0;
        this.countyid = 0;
      }
    },
    async cityid(newval) {
      if (newval > 0) {
        let res = await api.getcountylist(newval);
        this.county = res.data;
        this.countyid = 0;
      }
    },
    mobile(newval) {
      if (this.time == 0) {
        if (newval.length == 11) {
          this.sendcode = 1;
        } else {
          this.sendcode = 0;
        }
      }
    },
  },
};
</script>



<style lang="less" scoped>
.uploadBox {
  // width: 123px;
  // height: 73px;
  // background-color: pink;
  margin-left: 8px;
}
.fenImg {
  width: 123px;
  height: 73px;
  margin-right: 18px;
}
.fenTitle {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  position: absolute;
  top: 80px;
  left: 42px;
}
.fenTitle1 {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
  position: absolute;
  top: 80px;
  left: 62px;
}

.signBox {
  display: inline;
  position: relative;
}
.loginImg {
  width: 800px;
  height: 840px;
  float: left;
}
.FromBox {
  background-color: #fff;
  height: 840px;
  width: 640px;
  display: flex;
  justify-content: center;
}
.signMain {
  width: 426px;
  // height: 500px;
  // background-color: pink;
  margin-top: 64px;
}
h2 {
  margin-left: 45px;
}
.moldBox {
  margin-top: 28px;
}
.perBox,
.firmBox {
  height: 400px;
}
.moldNav {
  height: 40px;
  display: flex;
  cursor: pointer;
}
.company {
  margin-top: 16px;
}
.elect,
.companyTitle,
.siteTitle,
.ohoneTitle,
.businessTitle,
.companyTitle1,
.siteTitle1,
.rankTitle,
.siteTitler {
  font-size: 14px;
  line-height: 40px;
}
.elect {
  margin-left: -40px;
}
.companyTitle {
  margin-left: -28px;
}
.siteTitle1 {
  margin-left: -28px;
}
.siteTitle {
  margin-left: 2px;
}

.siteTitler {
  margin-right: 2px;
}
.ohoneTitle {
  margin-left: -12px;
}
.rankTitle {
  margin-right: 8px;
  margin-left: -27px;
}
.businessTitle {
  margin-right: 8px;
  margin-left: -27px;
}
.person,
.firm {
  margin-left: 8px;
  width: 101px;
  height: 40px;
  background-color: #f8fafc;
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
}
.personImg,
.firmImg {
  width: 22px;
  height: 22px;
  vertical-align: middle;
  margin-right: 4px;
}
.personTitle,
.firmTitle {
  vertical-align: middle;
}
.firm {
  margin-left: 28px;
}
.rank {
  cursor: pointer;
  display: flex;
  margin-top: 16px;
  position: relative;
}
input {
  outline: none;
}
.designation,
.designation1 {
  width: 352px;
  height: 40px;
  border: 1px solid #d9d9d9;
  padding-left: 12px;
  box-sizing: border-box;
  margin-left: 8px;
  border-radius: 8px;
}
.site {
  margin-top: 16px;
}
.phone {
  margin-top: 16px;
  position: relative;
}
.per,
.qi {
  width: 112px;
  height: 40px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #d9d9d9;
  padding-left: 8px;
}

.per {
  margin-left: 8px;
}
.qi {
  margin-left: 6px;
  margin-right: 2px;
}
.detail {
  width: 352px;
  height: 40px;
  margin-left: 40px;
  border: 1px solid #d9d9d9;
  padding-left: 12px;
  border-radius: 8px;
  margin-top: 16px;
  box-sizing: border-box;
}
.auth {
  padding-left: 12px;
  margin-left: 7px;
  width: 352px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
}
.gain,
.gain-x {
  width: 102px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  background: rgba(0, 0, 0, 0.25);
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  top: 58px;
  right: 33px;
  box-sizing: border-box;
  color: #fff;
}

.gain-g {
  width: 102px;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
  text-align: center;
  background-color: #2c68ff;
  font-size: 14px;
  border-radius: 8px;
  position: absolute;
  top: 58px;
  right: 33px;
  box-sizing: border-box;
  color: #fff;
}
.note {
  width: 232px;
  height: 40px;
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  padding-left: 12px;
  margin-top: 16px;
  box-sizing: border-box;
  margin-left: 40px;
}

.business {
  display: flex;
  margin-top: 16px;
  position: relative;
}
p .upload {
  position: absolute;
  top: 157px;
  left: 57px;
}

.el-upload-list--picture-card {
  width: 123px;
  height: 73px;
}
i {
  font-style: normal;
  font-size: 14px;
  color: #2c68ff;
  cursor: pointer;
}

.dealTitle {
  font-size: 14px;
  margin-left: 8px;
  vertical-align: top;
}
.deal {
  margin-left: 43px;
}
.refer {
  width: 360px;
  height: 40px;
  margin-left: 43px;
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
  margin-top: 24px;
  cursor: pointer;
  color: #fff;
  background-color: #2c68ff;
  border: 0;
}

.active {
  background: #2c68ff;
  color: #fff;
}

.business /deep/ .el-upload--picture-card {
  width: 123px;
  height: 72px;
  background-image: url("../assets/img/Group4334.png");
  background-size: 100% 100%;
  border-radius: 8px;
  border: 0;
}
.business /deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 123px;
  height: 73px;
}
.rank /deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 123px;
  height: 73px;
}

.rank /deep/ .el-upload--picture-card {
  width: 123px;
  height: 73px;
  background-image: url("../assets/img/Group3897.png");
  background-size: 100% 100%;
  border-radius: 8px;
  border: 0;
}
</style>